import { cartStorageName, userStorageName } from '@/services/ecommerce/apis/config';
import { DateTime } from 'luxon';
import { getItemFromLocalStorage, setItemInLocalStorage } from './session';

export const calculatePercentage = (d_price: any, price: any) =>
	!!parseFloat(price) && !!parseFloat(d_price) ? (100 - (d_price / price) * 100).toFixed(0) : 0;

export function validPhoneNumber(phone: any) {
	const regex = /(^(\+8801|8801|008801|01))(\d){9}$/;
	return regex.test(phone);
}

export const getQtyLabel = (i: number, medicine: any) => {
	if (!medicine) {
		return '';
	}
	if (medicine.form === medicine.unit) {
		const s = i === 1 ? '' : 's';
		return `${i} ${medicine.unit}${s}`;
	}
	const match = medicine.unit.match(/([0-9]+){1,3}/);
	const qty = match ? match[0] : 0;
	if (qty && `${qty} ${medicine.form}s` === medicine.unit) {
		return `${i * qty} ${medicine.form}s`;
	}
	return `${i} * ${medicine.unit}`;
};

export const textToSlug = (text: any): string =>
	text
		.toString()
		.trim()
		.toLowerCase()
		.replace(/\s+/g, '-')
		.replace(/[^\w\-]+/g, '')
		.replace(/\-\-+/g, '-')
		.replace(/^-+/, '')
		.replace(/-+$/, '');

export const titleToSlug = (brand: { name?: string; form?: string; strength?: string }): string => {
	const text = `${brand.name || ''}-${brand.form || ''}-${brand.strength || ''}`;
	return textToSlug(text);
};

export const setLocalData = (data: any, type: 'user' | 'cart') => {
	if (typeof window !== 'undefined') {
		if (type === 'cart') {
			setItemInLocalStorage(cartStorageName, data);
		} else if (type === 'user') {
			setItemInLocalStorage(userStorageName, data);
		}
	} else {
		console.log('Window Not Found!');
	}
};

export const isEmptyArray = (array: any[] | undefined) => {
	if (Array.isArray(array) && array.length > 0) {
		return false;
	}
	return true;
};

export const isEmptyObject = (obj: object | undefined) => {
	if (obj) {
		if (Object.keys(obj).length > 0) {
			return false;
		}
	}
	return true;
};

export const isLoggedInUser = () => {
	const isLogggedId = getItemFromLocalStorage(userStorageName);
	if (Boolean(isLogggedId)) return true;
	else false;
};

export const getFlatNumber = (num: number = 0) => {
	return num.toString().split('.')[0];
};

export const isServer = () => typeof window === 'undefined';

export const luxonFormattedDate = (isoTimestamp: any, onlyDate = false) => {
	if (!isoTimestamp) return 'N/A';

	if (onlyDate === true) {
		return DateTime.fromISO(isoTimestamp).toFormat('dd/LL/yyyy');
	}

	return DateTime.fromISO(isoTimestamp).toFormat('dd/LL/yyyy hh:mm a');
};

export const getFormattedDate = (isoTimestamp: any) => {
	if (!isoTimestamp) return 'N/A';

	const dateInstance = new Date(isoTimestamp);

	return `${dateInstance.toLocaleDateString('en-US', {
		dateStyle: 'medium'
	})} ${dateInstance.toLocaleTimeString()}`;
};

export function toQueryString(obj: any): string {
	const queryStringParts = [];

	for (const [key, value] of Object.entries(obj)) {
		if (Array.isArray(value)) {
			queryStringParts.push(`${key}=${encodeURIComponent(JSON.stringify(value))}`);
		} else {
			queryStringParts.push(`${key}=${encodeURIComponent(value as any)}`);
		}
	}

	const queryString = queryStringParts.join('&');

	return queryString;
}

export function getFormData(object: any) {
	const formData = new FormData();
	Object.keys(object).forEach((key) => formData.append(key, object[key]));
	return formData;
}

export function popupWindow(url: string, title: string, win: any, width: number, height: number) {
	const y = win.top.outerHeight / 2 + win.top.screenY - height / 2;
	const x = win.top.outerWidth / 2 + win.top.screenX - width / 2;
	return win.open(
		url,
		title,
		`toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
	);
}

export const getVariant = (status: string) => {
	switch (status) {
		case 'scheduled':
		case 'rescheduled':
		case 'pending':
		case 'confirmed':
			return 'orange';
		case 'processing':
		case 'collected':
		case 'tested':
			return 'yellow';
		case 'completed':
			return 'honeydew';
		case 'cancelled':
			return 'error';
		default:
			return 'primary';
	}
};

export const getText = (status: string) => {
	switch (status) {
		case 'scheduled':
		case 'rescheduled':
			return 'Scheduled';
		case 'pending':
		case 'confirmed':
			return 'Pending';
		case 'processing':
		case 'collected':
		case 'tested':
			return 'Processing';
		case 'completed':
			return 'Completed';
		case 'cancelled':
			return 'Cancelled';
		default:
			return 'Pending';
	}
};

export const getStatus = (status: string) => {
	switch (status) {
		case 'pending':
			return true;
		case 'confirmed':
			return true;
		case 'scheduled':
			return true;
		case 'rescheduled':
			return true;
		default:
			return false;
	}
};
